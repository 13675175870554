<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
const db = useFirestore()
const insights = useInsightsList(db)

// if isSuperadmin or refCompany has a bucket or refCompany status is demo
const hasInsights = computed(() => {
  return isSuperadmin || isAdmin || (!isSuperadmin && !isAdmin && insights.value.length > 0)
})
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item title="Dashboard" prepend-icon="$mdiCounter" to="/" />
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Productos" prepend-icon="$mdiDashboard" to="/products" />
    <v-divider v-if="hasInsights" />
    <v-list-item v-if="hasInsights" title="Recomendaciones" prepend-icon="$mdiAI" to="/insights" />
    <v-divider />
    <template v-if="isSuperadmin">
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="$mdiAIoutline" to="/adify/budget" title="FSBD Adify" />
        </template>
        <v-divider />
        <v-list-item title="Activables" prepend-icon="$mdiCheckActivable" to="/adify/budget" />
        <v-divider />
        <v-list-item title="Procesadas" prepend-icon="$mdiCheckProcessed" to="/adify/processed" />
        <v-divider />
        <v-list-item title="Vencidas" prepend-icon="$mdiCheckExpired" to="/adify/expired" />
      </v-list-group>
      <v-divider />
    </template>
<!--    <v-list-item v-if="isSuperadmin || isAdmin" title="CC AI" prepend-icon="$mdiChat" to="/ccai" />
    <v-divider v-if="isSuperadmin || isAdmin" />-->
    <v-list-item v-if="isSuperadmin || isAdmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin || isAdmin" />
  </v-list>
</template>
